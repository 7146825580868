/**
 * 用来保存全局常量
 * 模型服务器配置
 */
let ModelConfig = null;

// 上线时，看serverIp,部署到哪个服务器，一般需要置空
//模型列表 模型服务器配置
ModelConfig = {
  appKey: "A7A1D7D4F84328B2169419F8",
  appScrect: "TdCsAGoWmzTs1ACScU7pp5BP22O70y6XXvsyFqIZXVCVRG1p",
  serverIp: "",
};

//打包 server 置空
let server = "";
let APIURL = `${server}/api`;

// dev环境   私有云时需置空
let baseURL = "https://everybim.net";

let oemKey = "PublicCloud"; // PRIVATE 私有云, PublicCloud 公有云 ;控制进入 私有云 还是 公有云官网

// 128测试环境 https://dev.everybim.net/  http://192.168.1.128:32200   http://180.168.170.198:45503  http://zh.everybim.net:45503 https://sso-dev.everybim.net // 128外网地址
// let oauthURL = "https://sso-dev.everybim.net"; //http://zh.everybim.net:45361
// let consoleURL = "https://console.ezbim.net";
// let senceModel = {
//   modelId: "65421c7e282ac20393e21b74",
//   modelsdata: "65421f41282ac20393e21b78", //示例DEMO加载多模型所用
//   viewmodelId: "64e6f72cfbf87d0371a4b093", //示例DEMO视图嵌入用的模型Id
//   drawingLinkage: "6541c7f60ee3392a8545103d", //用于示例demo二三维图纸联动id
//   roomModelId: "643f5c8856820464bc2854be",
//   drawingId: "643fc21d19932e1fd6bbceb8",
//   roomVersion: 1,
//   linkageDataId: "65421e36aabbf6052d107210",
// };

// 官网 正式环境  https://everybim.net
let oauthURL = "https://sso.everybim.net"; //运维管理平台地址
let consoleURL = "https://console.ezbim.net"; //sso服务地址
let senceModel = {
  modelId: "6530957b7d17d5262a83e190", //用于官网示例demo
  modelsdata: "6530d3397d17d5262a83e1c5", //用于官网示例demo的加载多模型
  drawingLinkage: "6535eaf17d17d5262a83e296", //用于示例demo二三维图纸联动id
  roomModelId: "61e7e654ae06e02e242605f8",
  drawingId: "61b2fc36f2a3ee720095ed62",
  roomVersion: 2,
  linkageDataId: "653095b567cbda0e9b4aa3a0",
};

// 私有云   http://dnpc.yzyuncs.net  https://dev-npc.everybim.net/login
// let oauthURL = "https://sso.everybim.net"; //运维管理平台地址
// let consoleURL = "https://console.ezbim.net"; //sso服务地址
// let senceModel = {
//   modelId: "6260b83262c3e033fa78b263",
//   roomModelId: "61e7e654ae06e02e242605f8",
//   drawingId: "61b2fc36f2a3ee720095ed62",
// };

//场景示例模型
let EXAMPLE_MODEL = { modelId: "610a2c06be3f082135f8aea1", version: 1 };
let EXAMPLE_DRAWING = { modelId: "60a4d10fa0b039669aa2df0c", version: 1 };

let loginURL = `${oauthURL}?redirect=yizhu`; //译筑通行证地址
// let loginURL = `${oauthURL}?redirect=wang`; //译筑通行证地址
module.exports = {
  oemKey: oemKey,
  server: server,
  baseURL: baseURL,
  ApiURL: APIURL,
  oauthURL: oauthURL,
  loginURL: loginURL,
  consoleURL: consoleURL,
  modelConfig: ModelConfig,
  senceModel: senceModel,
  EXAMPLE_MODEL: EXAMPLE_MODEL,
  EXAMPLE_DRAWING: EXAMPLE_DRAWING,
};
